.pass-purchase-page-header {
    text-align: center !important;
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
    line-height: 1.2;
    font-weight: 500;
}

.pass-purchase-page-body {
    text-align: center;
    input {
        max-width: 200px;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
    }
}
.pass-purchase-page-body-details {
    text-align: justify;
    input {
        max-width: 200px;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
    }
}

.pass-purchase-page-footer {
    padding: 2rem 0rem;
    display: flex;
    justify-content: space-around;
    height: calc(4rem + 1.5rem + 6px + 2px); // vertical padding + input line height + input vertical padding + input vertical border
    input {
        width: 80px;
    }
}

.bg-white{
    height: 85%;
    overflow: hidden;
}

//--- Divs for centering items --//
.centered-text-div{
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: auto;
    width: 100%;
}

.centered-items-div{
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: auto;
    width: 100%;
}
    .buffer{
        font-size: max(10px, 1.48vw);
        margin-bottom: 2em;
    }
    .top{
        position: absolute;
        top: 0;
    }

//--- Product tier banners ---//
.product-display-header{
    z-index: 0;
    position: relative;
    overflow: hidden;
    font-family: 'Comfortaa';
    font-size: clamp(18px, 1.875vw, 28px);
    font-weight: bold;
    color: #ffffff;

    height: auto;
    margin-bottom: 1em;
}

.details-scroll-box{
    border: none;
    height: calc(100vh - 405px);
    overflow-y: auto;
}

.details-message{
    font-size: 0.66em;
}

.product-display-footer{
    z-index: 0;
    position: relative;
    overflow: hidden;
    font-family: 'Comfortaa';
    font-size: clamp(20px, 1.875vw, 28px);
    font-weight: bold;
    color: #ffffff;

    height: 54px;
}

.banner-shift{
    z-index: 1;
}

//--- Page body elements ---//
.purchase-details{
    font-family: 'Roboto';
    font: Roboto, "Helvetica Neue", sans-serif;
    font-size: clamp(12px, 1.87vmax, 26px);
    
    margin-top: -0.2em;
}

.product-list{
    list-style: none;
    text-align: justify;
    justify-content: center;
}

//--- Inputs ---//
.info-input-box{
    background-color: #f2f2f2;
    text-align: center;
    height: 1.5em;
    width: 10em;
}
    .long{
        width: 30vw;
    }

input[type="tel"],
input[type="email"],
input[type="text"],
input[type="date"]
{
    background-color: #f2f2f2;
    text-align: center;
    height: 1.5em;
    width: 10em;
}

input:invalid{
    box-shadow: 0 0 2px 1 px red;
}
input:focus:invalid{
    box-shadow: none;
}

.error-message{
    font-size: 0.7em;
    margin-top: 0.1em;
    margin-bottom: 0.3em;
    color: red;
}


//--- Footer for detail/confirm pages---//
footer{
    display: flex;
    align-items: flex-end;
}
    .footer{
        position: absolute;
        bottom: 0;
        left: 0;
        font-weight: bold;
        font-size: clamp(14px, 1.67vmin, 20px);

        background-color: #f2f2f2;
        height: 200px;
        width: 100%;
    }
        .footer-total-text{
            color: #262626;
            margin-left: 4.38vw;
        }
        .footer-price{
            position: absolute;
            margin-left: 4.38vw;
            font-size: 1.33em;
            top: 2px;
            height: 54px;
            line-height: 54px;
            vertical-align:auto
        }
        .footer-per-month{
            position: relative;
            display: inline-block;
            font-size: 0.67em;
            top: 0;
        }

        //--- Buttons for detail/confirm pages (within footer) ---//
        .btn-nav{
            font-family: 'Roboto';
            font-weight: lighter;
            font-size: clamp(12px, 1.64vmax, 28px);
            
            border: 2px solid;
            width: 10.79em;
            padding: 0.2em;
            margin-top: 1em;
            margin-bottom: 1em;
            margin-left: 0;
            margin-right: 0;
            opacity: 0.85;
            

            &:hover{
                border: 2px solid #f2f2f2;
                opacity: 1;
            }
            &:active{
                color: #fff;
            }
        }

        .white-hover:hover{
            border-color: #ffffff;
        }


.selection-button{
    margin-bottom: 1.5em;
}

.gift-choice-button{
    width: 12em;
    margin: 0.3em;
}

// Platinum
.platinum{
    color: var(--theme-tier-4-banner-color);
    border-color: var(--theme-tier-4-banner-color);
    background-color: #fff;     
    &:hover{
        color: #ffffff;
        background-color: var(--theme-tier-4-banner-color);
    }
}
.platinum-next{
    color: #ffffff;
    background-color: var(--theme-tier-4-banner-color);
    border-color: var(--theme-tier-4-banner-color);
    &:hover{
        background-color: var(--theme-tier-4-banner-color);
    }
}


// Gold
.gold{
    color: var(--theme-tier-3-banner-color);
    border-color: var(--theme-tier-3-banner-color); 
    background-color: #fff;
    &:hover{
        color: #ffffff;
        background-color: var(--theme-tier-3-banner-color);
    }
}
.gold-next{
    color: #ffffff;
    background-color: var(--theme-tier-3-banner-color);
    border-color: var(--theme-tier-3-banner-color);
    &:hover{
        background-color: var(--theme-tier-3-banner-color);
    }
}

// Silver
.silver{
    color: var(--theme-tier-2-banner-color);
    border-color: var(--theme-tier-2-banner-color); 
    background-color: #fff;
    &:hover{
        color: #ffffff;
        background-color: var(--theme-tier-2-banner-color);
    }
}
.silver-next{
    color: #ffffff;
    background-color: var(--theme-tier-2-banner-color);
    border-color: var(--theme-tier-2-banner-color);
    &:hover{
        background-color: var(--theme-tier-2-banner-color);
    }
}

// Bronze
.bronze{
    color: var(--theme-tier-1-banner-color);
    border-color: var(--theme-tier-1-banner-color);
    background-color: #fff;
    &:hover{
        color: #ffffff;
        background-color: var(--theme-tier-1-banner-color);
    }
}
.bronze-next{
    color: #ffffff;
    background-color: var(--theme-tier-1-banner-color);
    border-color: var(--theme-tier-1-banner-color);
    &:hover{
        background-color: var(--theme-tier-1-banner-color);
    }
}