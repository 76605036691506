.btn {
    font-family: 'Rift Soft';
    border-width: 3px;
    border-radius: 0px;
    font-size: 1.5rem;
    padding-top: 0px;
    padding-bottom: 0px;

    &.btn-sm {
        border-width: 1px;
        font-family: Roboto;
        font-size: 1rem;
        padding-top: 3px;
        padding-bottom: 3px;
    }
}