@import './scss/variables';
@import "../node_modules/bootstrap/scss/bootstrap.scss";
@import "./scss/pass-purchase.scss";
@import "./scss/buttons.scss";


/* See `node_modules\bootstrap\scss\mixins\_buttons.scss` for button-variant and button-outline-variant arguments */
.btn-platinum {
    @include button-variant(
        $background: $platinum,
        $border:$platinum,
        $color: $white,
        $hover-color: $white,
        $active-color: $white,
        $disabled-color: $white);
}
.btn-outline-platinum {
    @include button-outline-variant(
        $color: $platinum,
        $color-hover: $white,
        $active-color: $white);
}

.btn-gold {
    @include button-variant(
        $background:$gold,
        $border: $gold,
        $color: $white,
        $hover-color: $white,
        $active-color: $white,
        $disabled-color: $white);
}
.btn-outline-gold {
    @include button-outline-variant(
        $color: $gold,
        $color-hover: $white,
        $active-color: $white);
}

.btn-silver {
    @include button-variant(
        $background: $silver,
        $border: $silver,
        $color: $white,
        $hover-color: $white,
        $active-color: $white,
        $disabled-color: $white);
}
.btn-outline-silver {
    @include button-outline-variant(
        $color: $silver,
        $color-hover: $white,
        $active-color: $white);
}
.btn-bronze {
    @include button-variant(
        $background: $bronze,
        $border: $bronze,
        $color: $white,
        $hover-color: $white,
        $active-color: $white,
        $disabled-color: $white);
}
.btn-outline-bronze {
    @include button-outline-variant(
        $color: $bronze,
        $color-hover: $white,
        $active-color: $white);
}

@font-face {
    font-family: Comfortaa;
    src: url('./assets/font_comfortaa_bold.ttf');
}
@font-face {
    font-family: Roboto;
    src: url('./assets/font_roboto_regular.ttf');
}
@font-face {
    font-family: 'Rift Soft';
    src: url('./assets/font_rift-soft_bold.woff') format('woff');
    font-weight: bold;
}
@font-face {
    font-family: 'Rift Soft Demi';
    src: url('./assets/Rift Soft Demi.otf');
}
@font-face {
    font-family: 'Rift Soft Regular';
    src: url('./assets/Rift Soft Regular.otf');
}
@font-face {
    font-family: 'Rift Soft Regular';
    src: url('./assets/RiftSoft-Regular.woff');
}

html, body {
    height: 100%;
    font-family: Roboto, 'Segoe UI', 'Helvetica Neue', Arial, Comfortaa
}

.w-100 { width: 100%; }

.font-rift-soft { font-family: 'Rift Soft'; }
.font-roboto { font-family: 'Roboto'; }

.page-bottom {
   position:fixed;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    bottom: 0px;
}
.page-top {
    position: absolute;
    width: 100%;
    top: 0px;
}
.no-pad{
    padding-left: 0px !important;
    padding-right: 0px !important;
}


input {
    border: 2px solid $input-border-color;
    border-radius: 5px;
    outline: none;
    &:focus, &:active {
        border-color: $active-input-highlight-color;
    }
}
html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

.invalid-feedback {
    display: block;
}